










































import { Component, Vue } from "vue-property-decorator";

import ContactEmailPopin from "@/components/ContactEmailPopin.vue";

import FacebookIcon from "@/scalar/social-facebook.svg";
import TwitterIcon from "@/scalar/social-twitter.svg";
import EmailIcon from "@/scalar/social-email.svg";

import CompanyLogo from "@/scalar/company-logo.svg";

interface ISiteFooterVm {
    showEmail: boolean;
}

@Component({
    components: { FacebookIcon, TwitterIcon, EmailIcon, ContactEmailPopin, CompanyLogo },

    data(): ISiteFooterVm {
        return {
            showEmail: false
        };
    }
})
export default class SiteFooter extends Vue {
    private onShowEmailInfo(): void {
        const vm = this.$data as ISiteFooterVm;
        vm.showEmail = true;
    }

    private onCloseEmailInfo(): void {
        const vm = this.$data as ISiteFooterVm;
        vm.showEmail = false;
    }
}
