






















import { getManual } from "@/services/doc-services";
import { Component, Watch, Vue } from "vue-property-decorator";

import { RawLocation } from "vue-router";

interface IDocRef {
    title: string;
    id: string;
    children: IDocRef[]|null;
}

interface IDocPageVm {
    id: string;

    loaded: boolean;
    reqNo: number;

    contentsRef: IDocRef[];
}

interface IDocPageVmIncUntracked extends IDocPageVm {
    scrollTickId: number|null;
    replacingRoute: boolean;
}

@Component({
    components : { },

    data(): IDocPageVm {
        return {
            id: "",
            loaded: false,
            reqNo: 0,
            contentsRef: []
        };
    }
})
export default class DocPage extends Vue {
    private get focusId(): string {
        return this.$route.params.hasOwnProperty("id") &&
               typeof this.$route.params.id !== "undefined" ? ""+this.$route.params.id : "";
    }

    @Watch("$route")
    private onRouteChange():void {
        const vm = this.$data as IDocPageVmIncUntracked;
        if (!vm.loaded) { return; }

        // If the route changes via scroll, but the article is on display don't force the scrolling
        const offset = this.getArticlePosition(this.focusId);
        if (!vm.replacingRoute || (offset !== null && offset !== 0)) { this.scrollToFocusId(); }
    }

    private getArticlePosition(id: string): number|null {
        if (id.length < 1) { return null; }

        const el = document.getElementById(id);
        if (el === null) { return null; }

        const rect = el.getBoundingClientRect();
        const limit = window.innerHeight * 0.2;
        let offset = 0;
        if (rect.top < 0 && rect.bottom < limit) {
            offset = -1; // Article is further up
        } else if (rect.top > (window.innerHeight - limit)) {
            offset = 1; // Article is further down
        }

        return offset;
    }

    private doTriggerScroll(offset: number): void {
        const vm = this.$data as IDocPageVmIncUntracked;
        const existing = this.focusId;
        const idx = vm.contentsRef.findIndex(c => c.id === existing);
        if (idx < 0) { return; }

        let possible:(IDocRef[]|null) = null;
        if (offset > 0) { // Current article is now further down
            // Find the next one behind
            possible = vm.contentsRef.slice(0, idx).reverse();
        } else if (offset < 0) { // Current article has now been passed
            // Find the next one forward
            possible = vm.contentsRef.slice(idx+1);
        }

        const matchRefIdx = possible.findIndex(c => this.getArticlePosition(c.id) === 0);
        if (matchRefIdx >= 0) {
            const location:RawLocation = {
                name: "docs",
                params: { id: possible[matchRefIdx].id }
            };
            vm.replacingRoute = true;
            this.$router.replace(location).finally(() => {
                const localVm = this.$data as IDocPageVmIncUntracked;
                localVm.replacingRoute = false;
            });
        }
    }

    private onScrollUpdate(): void {
        const vm = this.$data as IDocPageVmIncUntracked;
        if (typeof vm.scrollTickId !== "undefined") { return; }

        vm.scrollTickId = window.setTimeout(() => {
            const focusId = this.focusId;
            if (focusId.length > 0) {
                const offset  = this.getArticlePosition(focusId);
                if (offset !== null) {
                    if (offset !== 0) {
                        this.doTriggerScroll(offset);
                    }
                }
            }

            delete vm.scrollTickId;
        }, 150);
    }

    private scrollToFocusId(): void {
        let focusId = this.focusId;
        if (focusId.length < 1) { focusId = "toc"; }
        const el = document.getElementById(focusId);
        if (el === null) { return; }
        el.scrollIntoView();
    }

    private get contentId(): string {
        const vm = this.$data as IDocPageVm;
        return "content-"+vm.id;
    }

    private startManualLoad(): void {
        const vm = this.$data as IDocPageVm;
        const expectedReqNo = vm.reqNo + 1;
        vm.reqNo = expectedReqNo;

        const promise = getManual(this.$i18n.locale);
        promise.then(resp => {
            const localVm =this.$data as IDocPageVm;
            if (localVm.reqNo !== expectedReqNo) { return; } // No longer valid

            this.displayManual(resp.data);
        });
    }

    private displayManual(loadedDoc: Document): void {
        const vm = this.$data as IDocPageVm;
        vm.loaded = true;

        const articleNodes:HTMLElement[] = [];
        loadedDoc.querySelectorAll("article").forEach(el => {
            articleNodes.push(el.cloneNode(true) as HTMLElement);
        });

        vm.contentsRef = articleNodes.map(articleEl => {
            if (articleEl.id.length < 1 || articleEl.firstElementChild == null) { return null; }

            const docRef:IDocRef = {
                title: articleEl.firstElementChild.textContent,
                id: articleEl.id,
                children: null
            };

            return docRef;
        }).filter(dr => dr !== null);

        if (articleNodes.length > 0) {
            window.setTimeout(() => {
                const mountEl = document.getElementById(this.contentId);
                while (mountEl.hasChildNodes()) { mountEl.removeChild(mountEl.lastChild); }

                articleNodes.forEach(el => mountEl.appendChild(el));

                if (this.focusId.length > 0) {
                    this.scrollToFocusId();
                } else {
                    if (vm.contentsRef.length > 0) {
                        const id = vm.contentsRef[0].id;
                        if (this.getArticlePosition(id) === 0) {
                            const location:RawLocation = { name: "docs", params: { id } };
                            this.$router.replace(location);
                        }
                    }
                }
            }, 10);
        }
    }

    private beforeMount(): void {
        this.$watch("$i18n.locale",
            (newLocale: string, oldLocale: string): void => {
                if (newLocale === oldLocale) { return; }
                this.startManualLoad(); // Reset store button on language change
            },
            { immediate: true }
        );

        const vm = this.$data as IDocPageVm;
        const arr = new Uint32Array(1);
        window.crypto.getRandomValues(arr);
        vm.id = "frame-" + arr[0].toString(16).slice(2,8);
    }

    private mounted(): void {
        this.startManualLoad();
    }

    private beforeDestroy(): void {
        const vm = this.$data as IDocPageVmIncUntracked;
        if (typeof vm.scrollTickId !== "undefined") {
            window.clearTimeout(vm.scrollTickId);
            delete vm.scrollTickId;
        }
    }
}
