import axios, { AxiosPromise } from "axios";

function getIntroAnimSlide(slideNo: number, lang: string): AxiosPromise<XMLDocument> {
    return axios.get("/assets/carousel/"+lang+"/intro"+slideNo+".svg", {
        transformResponse: data => {
            const parser = new DOMParser();
            const doc = parser.parseFromString(data, "image/svg+xml");
            return doc;
        }
    });
}

export { getIntroAnimSlide };
