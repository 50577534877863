















































































import { Component, Vue } from "vue-property-decorator";

import ContactEmailPopin from "@/components/ContactEmailPopin.vue";

import EmailIcon from "@/scalar/social-email.svg";
import LinkedInIcon from "@/scalar/social-linkedin.svg";

interface IContactable {
    addr: string;
    linkedIn: string;
}

interface ITranslator extends IContactable {
    name: string;
    lang: string;
}

interface IArtist extends IContactable {
    name: string;
    contrib: string[];
}

interface ICreditsViewModel {
    emailUser: string;
    emailDomain: string;
    translators: ITranslator[];
    artists: IArtist[];
}

@Component({
    components: { ContactEmailPopin, EmailIcon, LinkedInIcon },

    data(): ICreditsViewModel {
        return {
            emailUser: "",
            emailDomain: "",
            translators: [
                { name: "Christian Sester", lang: "de", addr: "", linkedIn:"" },
                { name: "Eric Chane-Thien", lang: "fr", addr: "", linkedIn:"" },
                { name: "Emanuele Arioli", lang: "it", addr: "", linkedIn:"" },
                { name: "Sofia El Bamrani", lang: "ja", addr: "gmail.com|sofia7sawako", linkedIn:"" },
                { name: "Misael Dos Santos Matos", lang: "ptBr", addr: "", linkedIn:"" },
                {
                    name: "Helena Aleman Heiskanen",
                    lang: "es",
                    addr: "hotmail.com|helheis",
                    linkedIn:"helena-aleman-heiskanen-70956712a"
                },
                { name: "Do Khoa Nam", lang: "vi", addr: "", linkedIn:"" },
                { name: "Chris Poon", lang: "zh", addr: "", linkedIn:"" }
            ],
            artists: [{
                name: "Nhat-Tam Huynh",
                contrib: ["corpLogo", "appLogo"],
                addr: "yahoo.com|Leonnhattam",
                linkedIn: "hntam"
            }]
        };
    }
})
export default class CreditsPage extends Vue {
    private onShowEmailInfo(addr: string):void {
        const parts = addr.split("|");
        if (parts.length < 2) { return; }

        const vm = this.$data as ICreditsViewModel;
        vm.emailUser = parts[1];
        vm.emailDomain = parts[0];
    }

    private onHideEmailInfo():void {
        const vm = this.$data as ICreditsViewModel;
        vm.emailUser = "";
        vm.emailDomain = "";
    }
}
