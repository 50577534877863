








































import { Component, Watch, Prop, Vue } from "vue-property-decorator";

import { getIntroAnimSlide } from "@/services/slide-services";

import BlankSlide from "@/scalar/blank-slide.svg";
import NextIcon from "@/scalar/next-icon.svg";
import PauseIcon from "@/scalar/pause-icon.svg";
import PrevIcon from "@/scalar/prev-icon.svg";
import ResumeIcon from "@/scalar/resume-icon.svg";

const SlideTimes = [2300,6000,6000,12000,6000];

interface ICarouselViewModel {
    slideContainerId: string;
    slide: number;
    pauseTime: number|undefined;
}

interface ICarouselVmIncUntracked extends ICarouselViewModel {
    timerId: number|undefined;
    startTime: number|undefined;
    timeOnPause: number|undefined;
}

@Component({
    data() : ICarouselViewModel {
        return {
            slideContainerId: "",
            slide: 0,
            pauseTime: undefined
        };
    },

    components : { BlankSlide, NextIcon, PauseIcon, PrevIcon, ResumeIcon }
})
export default class Carousel extends Vue {
    @Prop({ default: "" }) public idList: string;

    private get isAnimPaused(): boolean {
        const vm = this.$data as ICarouselVmIncUntracked;
        return typeof vm.pauseTime !== "undefined";
    }

    private doSlideChange(offset: number, userReq: boolean): void {
        const vm = this.$data as ICarouselVmIncUntracked;

        // If the animation not has just been started, then simply restart the current animation
        const now = new Date().getTime();
        if (userReq && offset < 0 && !this.isAnimPaused && (now - vm.startTime) > 1000) { offset = 0; }

        let nextSlide = vm.slide + offset;
        if (nextSlide < 1 || nextSlide >= SlideTimes.length) { nextSlide = 1; }

        if (userReq) { // React immediate to user request and clear current anim
            const scEl = document.getElementById(vm.slideContainerId);
            const count = scEl.childElementCount;
            for (let i=0; i<count; i++) {
                const child = scEl.children[i] as SVGElement;
                if (child.classList.contains("to-be-kept")) {
                    child.style.display = "";
                    continue;
                }
                child.style.display = "none";
                scEl.removeChild(child);
            }
        }

        this.startSlide(nextSlide);
    }

    private onSlidePlayPause(): void {
        const vm = this.$data as ICarouselVmIncUntracked;
        const scEl = document.getElementById(vm.slideContainerId);
        const svgEl = scEl.querySelector("svg:not(.to-be-kept)");
        if (svgEl === null) { return; }

        const animElements = svgEl.querySelectorAll(".has-anim");
        if (this.isAnimPaused) {
            // How to find tou the time on pause
            const now = new Date().getTime();
            const timeUsed = now - vm.pauseTime;
            vm.pauseTime = undefined;

            // Kill any "next slide" timer
            if (typeof vm.timerId !== "undefined") {
                window.clearTimeout(vm.timerId);
                vm.timerId = undefined;
            }

            // Calculate time remaining
            if (typeof vm.timeOnPause === "undefined") {
                vm.timeOnPause = timeUsed;
            } else {
                vm.timeOnPause += timeUsed;
            }

            const timeRemaining = now - (vm.startTime + vm.timeOnPause);

            // Resume playback
            const count = animElements.length;
            for (let index = 0; index < count; index++) {
                const animElement = animElements[index] as SVGElement;
                animElement.style.animationPlayState = "running";
            }

            // Setup the "next slide" timeout
            if (timeRemaining > 0) {
                vm.timerId = window.setTimeout(() => { this.onSlideChangeTimeout(); }, timeRemaining);
            }
        } else {
            // Pause playback
            vm.pauseTime = new Date().getTime();

            const count = animElements.length;
            for (let index = 0; index < count; index++) {
                const animElement = animElements[index] as SVGElement;
                animElement.style.animationPlayState = "paused";
            }
        }
    }

    @Watch("slide")
    private onSlideChange(newSlideNo: number): void {
        if (newSlideNo < 1) {
            const vm = this.$data as ICarouselViewModel;
            const scEl = document.getElementById(vm.slideContainerId);
            if (scEl !== null) {
                const blankEl:SVGElement = scEl.querySelector("svg.to-be-kept");
                blankEl.style.display = "";

                const svgEl = scEl.querySelector("svg:not(.to-be-kept)");
                if (svgEl !== null) { scEl.removeChild(svgEl); }
            }
            return;
        }

        const promise = getIntroAnimSlide(newSlideNo, this.$i18n.locale);
        promise.then(resp => {
            const vm = this.$data as ICarouselViewModel;
            const scEl = document.getElementById(vm.slideContainerId);
            if (scEl === null) { return; }

            const scElCount = scEl.childElementCount;
            for (let i=0; i<scElCount; i++) {
                const child = scEl.children[i] as SVGElement;
                if (child.classList.contains("to-be-kept")) {
                    child.style.display = "none";
                    continue;
                }
                child.style.display = "";
                scEl.removeChild(child);
            }

            const svgEl = resp.data.querySelector("svg");
            if (this.isAnimPaused) {
                const animElements = svgEl.querySelectorAll(".has-anim");
                const count = animElements.length;
                for (let index = 0; index < count; index++) {
                    const animElement = animElements[index] as SVGElement;
                    animElement.style.animationPlayState = "paused";
                }
            }

            scEl.appendChild(svgEl);
        });
    }

    private onSlideChangeTimeout(): void {
        if (this.isAnimPaused) { return; } // paused

        this.doSlideChange(1, false);
    }

    private startSlide(slideNo: number): void {
        const vm = this.$data as ICarouselVmIncUntracked;

        if (typeof vm.timerId !== "undefined") {
            window.clearTimeout(vm.timerId);
            vm.timerId = undefined;
        }

        vm.startTime = new Date().getTime();

        // Handle paused animations
        if (this.isAnimPaused) {
            vm.pauseTime = vm.startTime;
            vm.timeOnPause = 0;
        } else {
            vm.pauseTime = undefined;
            vm.timeOnPause = undefined;
        }

        vm.timerId = window.setTimeout(() => { this.onSlideChangeTimeout(); }, SlideTimes[slideNo]);

        // Special case for resetting an existing slide
        if (vm.slide === slideNo) {
            vm.slide = -1; // Force the watch function to trigger
            setTimeout(() => { vm.slide = slideNo; }, 10);
            return;
        }

        vm.slide = slideNo;
    }

    public beforeMount(): void {
        this.$watch("$i18n.locale",
            (newLocale: string, oldLocale: string): void => {
                if (newLocale === oldLocale) { return; }

                // Reset existing slide on language change
                const scopedVm = this.$data as ICarouselVmIncUntracked;
                if (scopedVm.slide < 0) { return; } // In transition, no need to restart
                const slideNo = scopedVm.slide > 1 ? scopedVm.slide : 1;
                this.startSlide(slideNo);
            },
            { immediate: true }
        );
    }

    public mounted(): void {
        const vm = this.$data as ICarouselVmIncUntracked;
        vm.slide = 0;

        const tmpArray = new Uint32Array(1);
        window.crypto.getRandomValues(tmpArray);
        vm.slideContainerId = "scid-"+tmpArray[0].toString(16);

        this.startSlide(0);
    }

    public beforeDestroy(): void {
        const vm = this.$data as ICarouselVmIncUntracked;
        if (typeof vm.timerId !== "undefined") {
            window.clearTimeout(vm.timerId);
            vm.timerId = undefined;
        }
    }
}
