
























import { Component, Vue } from "vue-property-decorator";

import TaskDialog from "@/components/TaskDialog.vue";

import { AvailableLanguages } from "@/lang/common";
import { loadLanguageAsync } from "@/setup/i18n-setup";

interface ILanguageChoice {
    code: string;
    desc: string;
}

interface ILanguageSelectViewModel {
    languages: ILanguageChoice[];
    selectedLocale: string;
    feedbackMsg: string;
    enabled: boolean;
}

@Component({
    components : { TaskDialog },

    data(): ILanguageSelectViewModel {
        return {
            languages: [],
            selectedLocale: "",
            feedbackMsg: "",
            enabled: false
        };
    }
})
export default class LanguageSelect extends Vue {
    private get currentLocale(): string {
        return this.$i18n.locale;
    }

    private get selectedLanguageDesc(): string {
        const vm = this.$data as ILanguageSelectViewModel;
        const lang = vm.languages.find(l => l.code === this.$i18n.locale);
        return lang !== null ? lang.desc : "";
    }

    private onFeedbackMsgDialogClose(): void {
        const vm = this.$data as ILanguageSelectViewModel;
        vm.feedbackMsg = "";
    }

    private onLanguageChange(locale: string): void {
        const vm = this.$data as ILanguageSelectViewModel;
        vm.enabled = false;

        const promise = loadLanguageAsync(locale);
        promise.then(() => {
            vm.selectedLocale = locale;
            vm.enabled = true;
        }).catch(() => {
            vm.feedbackMsg = "languageSelect.unableToLoad";
            vm.enabled = true;
        });
    }

    private beforeMount(): void {
        const vm = this.$data as ILanguageSelectViewModel;
        const langs = Object.getOwnPropertyNames(AvailableLanguages);

        langs.forEach(lang => {
            vm.languages.push({ code: lang, desc: AvailableLanguages[lang] });
        });

        vm.selectedLocale = this.currentLocale;
        vm.enabled = true;
    }
}
