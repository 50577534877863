








































import { Component, Prop, Vue } from "vue-property-decorator";

import PopIn from "@/templates/Popin.vue";

import StopIcon from "@/scalar/stop-icon.svg";

const eventRegex = /^([a-z]+)([a-z-]+)?([a-z]+)?$/;

@Component({
    components: { PopIn, StopIcon }
})
export default class TaskDialog extends Vue {
    @Prop({ default: "" }) public message: string;
    @Prop({ default: "" }) public icon: string;

    @Prop({ default: null }) public act1label: string|null;
    @Prop({ default: null }) public act2label: string|null;
    @Prop({ default: null }) public act3label: string|null;

    @Prop({ default: null }) public act1event: string|null;
    @Prop({ default: null }) public act2event: string|null;
    @Prop({ default: null }) public act3event: string|null;

    @Prop({ default: null }) public exitEvent: string|null;

    private getButtonLabel(label: string): string {
        if (label === null) { return ""; }
        return this.$i18n.t(label).toString();
    }

    private fireButtonClickEvent(event: string): void {
        if (event === null || !eventRegex.test(event)) {
            this.$emit("close");
            return;
        }
        this.$emit(event);
    }

    private onButton1Click(): void { this.fireButtonClickEvent(this.act1event); }
    private onButton2Click(): void { this.fireButtonClickEvent(this.act2event); }
    private onButton3Click(): void { this.fireButtonClickEvent(this.act3event); }

    private onPopinClose(): void {
        const event = this.exitEvent;
        if (event === null || !eventRegex.test(event)) { this.$emit("close"); }
        this.$emit(event);
    }

    private get iconName(): string {
        if (this.icon !== "stop") { return ""; }
        return this.icon;
    }

    private get button1Label(): string {
        const label = this.getButtonLabel(this.act1label);
        if (label.length < 1) {
            return this.$i18n.t("taskDialog.okButtonLabel").toString();
        }
        return label;
    }

    private get button2Label(): string { return this.getButtonLabel(this.act2label); }
    private get button3Label(): string { return this.getButtonLabel(this.act3label); }

    private get messageText(): string {
        const label = this.$i18n.t(this.message).toString();
        return label;
    }
}
