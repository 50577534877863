


















import { Component, Vue } from "vue-property-decorator";
import { doesOsSupportTestApp } from "@/services/os";
import { getReleaseFiles } from "@/services/download-services";

import MsStoreButton from "@/components/download/MsStoreButton.vue";
import DownloadIcon from "@/scalar/download-icon.svg";

const StateDisabled = -1;
const StatePending = 0;
const StateStarted = 1;

interface IDownloadPanelViewModel {
    files: string[];
    downloadState: number;
}

@Component({
    components : { DownloadIcon, MsStoreButton },

    data(): IDownloadPanelViewModel {
        return {
            files: [],
            downloadState: StateDisabled
        };
    }
})
export default class DownloadPanel extends Vue {
    private onDownload(): void {
        const vm = this.$data as IDownloadPanelViewModel;
        if (vm.downloadState !== StatePending || vm.files.length < 1) { return; }

        window.location.href = "./assets/" + vm.files[0];
        vm.downloadState = StateStarted;

        setTimeout(() => {
            vm.downloadState = StatePending;
        }, 3000);
    }


    private beforeMount(): void {
        const vm = this.$data as IDownloadPanelViewModel;

        vm.downloadState = doesOsSupportTestApp() ? StatePending : StateDisabled;
        if (vm.downloadState === StateDisabled) { return; }
    }

    private mounted(): void {
        const vm = this.$data as IDownloadPanelViewModel;
        const tmpArray = new Uint32Array(1);
        window.crypto.getRandomValues(tmpArray);

        if (vm.downloadState === StatePending) {
            const promise = getReleaseFiles();
            promise.then(resp => { vm.files = resp.data; });
        }
    }
}
