import axios, { AxiosPromise } from "axios";

function getManual(lang: string): AxiosPromise<Document> {
    return axios.get("/assets/manual/"+lang+".html", {
        transformResponse: data => {
            const parser = new DOMParser();
            const doc = parser.parseFromString(data, "text/html");
            return doc;
        }
    });
}

export { getManual };
