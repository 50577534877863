import { RouteConfig } from "vue-router";
import CreditsPage from "@/pages/CreditsPage.vue";
import DocPage from "@/pages/DocPage.vue";
import NotFoundPage from "@/pages/NotFoundPage.vue";
import WelcomePage from "@/pages/WelcomePage.vue";

const routes: RouteConfig[] = [{
    component: WelcomePage,
    name: "home",
    path: "/home"
},{
    component: CreditsPage,
    name: "credits",
    path: "/credits"
},{
    component: DocPage,
    name: "docs",
    path: "/docs/:id?"
},{
    name: "root",
    path: "/",
    redirect: "/home"
},{
    component: NotFoundPage,
    path: "*"
}];

export { routes };
