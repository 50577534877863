












import { Component, Vue } from "vue-property-decorator";

import Carousel from "@/components/Carousel.vue";
import DownloadPanel from "@/components/DownloadPanel.vue";
import Splash from "@/components/Splash.vue";

interface IWelcomePageVm {
    showPopin: boolean;
}

@Component({
    components : { Carousel, DownloadPanel, Splash },

    data(): IWelcomePageVm {
        return { showPopin: true };
    }
})
export default class WelcomePage extends Vue {
    private onPopinClose(): void {
        const vm = this.$data as IWelcomePageVm;
        vm.showPopin = false;
    }
}
