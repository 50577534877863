/* eslint-disable max-len */
/* tslint:disable:max-line-length */
const messages = {
    siteName : "Fluidité",
    companyName : "Code 29 Development",

    startCopyright: "2016",
    endCopyright: "2021",

    colonSuffix : "：",
    loading: "載入中",
    pageNotFound: "抱歉，找不到頁面",

    download: "下載",
    downloadRequested: "已徵求下載",

    languageSelect : {
        unableToLoad: "未能載入所選語言"
    },

    doc: {
        tableOfContents: "目錄"
    },

    credits: {
        intro: "感謝以下人士為此計劃提供重要貢獻。",
        translationHeader: "翻譯",
        artworkHeader: "美術",
        contribHeader: "嗚謝",
        languageHeader: "語言",
        authorHeader: "作者",
        emailLabel: "電郵地址",

        lang : {
            de: "德文",
            es: "西班牙文",
            fr: "法文",
            it: "意大利文",
            ja: "日文",
            ptBr: "巴西葡文",
            vi: "越南文",
            zh: "繁體中文"
        },

        art: {
            corpLogo: "Code29標誌",
            appLogo: "Fluidité標誌"
        }
    },

    nav: {
        mainPageTitle: "開始",
        docPageTitle: "說明文件",
        creditsPageTitle: "嗚謝"
    },

    carousel : {
        pauseButtonLabel: "暫停",
        resumeButtonLabel: "繼續",
        nextButtonLabel: "下一步",
        prevButtonLabel: "上一步"
    },

    taskDialog : {
        okButtonLabel: "OK"
    },

    footer : {
        contactUs : "聯絡我們",
        copyrightNotice : "版權所有© {start}-{end} {company}，翻版必究。",
        findUsOnFacebook : "前往我們的Facebook",
        followUsOnTwitter : "追蹤我們的Twitter",
        contactUsViaEmail : "給我們發送電郵",
        linkFacebook: "https://www.facebook.com/FluiditeApp",
        linkTwitter: "https://twitter.com/FluiditeApp",
        linkEmailUser: "contact",
        linkEmailDomain: "fluidite.app"
    }
};

export { messages };
