






import { Component, Vue } from "vue-property-decorator";
import { getMicrosoftStoreButton } from "@/services/download-services";

interface IMsStoreButtonViewModel {
    msStoreContainerId: string;
}

@Component({
    data(): IMsStoreButtonViewModel {
        return {
            msStoreContainerId: ""
        };
    }
})
export default class MsStoreButton extends Vue {
    private get msStoreLink(): string {
        const id = "9P5T2L2QM341";
        return "//www.microsoft.com/store/apps/"+id+"?cid=storebadge&ocid=badge";
    }

    private launchMsStoreButtonLoad(): void {
        const promise = getMicrosoftStoreButton(this.$i18n.locale);
        promise.then(resp => {
            const vm = this.$data as IMsStoreButtonViewModel;
            const containerEl = document.getElementById(vm.msStoreContainerId);
            while(containerEl.lastChild !== null) { containerEl.removeChild(containerEl.lastChild); }

            const svgEl = resp.data.querySelector("svg");
            if (svgEl === null) { return; }
            svgEl.setAttribute("width","100%"); // Chrome
            containerEl.appendChild(svgEl);
        });
    }

    private beforeMount(): void {
        this.$watch("$i18n.locale",
            (newLocale: string, oldLocale: string): void => {
                if (newLocale === oldLocale) { return; }
                this.launchMsStoreButtonLoad(); // Reset store button on language change
            },
            { immediate: true }
        );
    }

    private mounted(): void {
        const vm = this.$data as IMsStoreButtonViewModel;
        const tmpArray = new Uint32Array(1);
        window.crypto.getRandomValues(tmpArray);
        vm.msStoreContainerId = "msstorewrapper-"+tmpArray[0].toString(16);

        this.launchMsStoreButtonLoad();
    }
}
