













import { Component, Prop, Vue } from "vue-property-decorator";

import PopIn from "@/templates/Popin.vue";

@Component({
    components: { PopIn }
})
export default class ContactEmailPopin extends Vue {
    @Prop({ default: "" }) public title: string;
    @Prop({ default: "" }) public user: string;
    @Prop({ default: "" }) public domain: string;

    private onPopinClose(): void {
        this.$emit("close");
    }

    private get emailAddressLabel(): string {
        return this.user + "@" + this.domain;
    }

    private get emailAddressHref(): string {
        return "mailto:" + this.user + "@" + this.domain;
    }
}
