/* eslint-disable max-len */
/* tslint:disable:max-line-length */
const messages = {
    siteName : "Fluidité",
    companyName : "Code 29 Development",

    startCopyright: "2016",
    endCopyright: "2021",

    colonSuffix : ":",
    loading: "Loading",
    pageNotFound: "Page not found. Sorry about that.",

    download: "Download",
    downloadRequested: "Download Requested",

    languageSelect : {
        unableToLoad: "Unable to load selected language."
    },

    doc: {
        tableOfContents: "Table of Contents"
    },

    credits: {
        intro: "Thanks to all the following people who have made significant contributions to the development of the project.",
        translationHeader: "Translation",
        artworkHeader: "Artwork",
        contribHeader: "Contribution",
        languageHeader: "Language",
        authorHeader: "Author",
        emailLabel: "Email Address",

        lang : {
            de: "German",
            es: "Spanish",
            fr: "French",
            it: "Italian",
            ja: "Japanese",
            ptBr: "Portuguese (Brazilian)",
            vi: "Vietnamese",
            zh: "Traditional Chinese"
        },

        art: {
            corpLogo: "Logo for Code29",
            appLogo: "Logo for Fluidité"
        }
    },

    nav: {
        mainPageTitle: "Start",
        docPageTitle: "Documentation",
        creditsPageTitle: "Credits"
    },

    carousel : {
        pauseButtonLabel: "Pause",
        resumeButtonLabel: "Resume",
        nextButtonLabel: "Next",
        prevButtonLabel: "Previous"
    },

    taskDialog : {
        okButtonLabel: "OK"
    },

    footer : {
        contactUs : "Contact us",
        copyrightNotice : "Copyright © {start}-{end} {company}. All rights reserved.",
        findUsOnFacebook : "Find us on Facebook",
        followUsOnTwitter : "Follow us on Twitter",
        contactUsViaEmail : "Send us an email",
        linkFacebook: "https://www.facebook.com/FluiditeApp",
        linkTwitter: "https://twitter.com/FluiditeApp",
        linkEmailUser: "contact",
        linkEmailDomain: "fluidite.app"
    }
};

export { messages };
