import axios, { AxiosPromise } from "axios";

function getReleaseFiles(): AxiosPromise<string[]> {
    return axios.get("/releases.txt", {
        transformResponse: data => data.split("\n")
    });
}

function getMicrosoftStoreButton(lang: string): AxiosPromise<XMLDocument> {
    return axios.get("/assets/download-button/"+lang+"/get-it-from-microsoft.svg", {
        transformResponse: data => {
            const parser = new DOMParser();
            const doc = parser.parseFromString(data, "image/svg+xml");
            return doc;
        }
    });
}

export { getReleaseFiles, getMicrosoftStoreButton };
