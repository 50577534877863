



















import { Component, Vue } from "vue-property-decorator";

interface INavBarViewModel {
}

@Component({
    components : {},

    data(): INavBarViewModel {
        return {
        };
    }
})
export default class NavBar extends Vue {
}
