/* tslint:disable:max-line-length */
const messages = {
    siteName : "Fluidité",
    companyName : "Code 29 Development",

    startCopyright: "2016",
    endCopyright: "2021",

    colonSuffix : " : ",
    loading: "Chargement en cours",
    pageNotFound: "Page introuvable. Désolé.",

    download : "Télécharger",
    downloadRequested : "Requête de téléchargement effectuée",

    languageSelect : {
        unableToLoad : "Le chargement de la langue a échoué."
    },

    carousel : {
        pauseButtonLabel: "Pause",
        resumeButtonLabel: "Reprendre",
        nextButtonLabel: "Suivant",
        prevButtonLabel: "Précedent"
    },

    nav: {
        mainPageTitle: "Accueil",
        docPageTitle: "Documentation",
        creditsPageTitle: "Crédits"
    },

    doc: {
        tableOfContents: "Sommaire"
    },

    credits: {
        intro: "Nous remercions les personnes ci-dessous qui ont contribué au développement de ce projet.",
        translationHeader: "Traduction",
        artworkHeader: "Art",
        contribHeader: "Apport",
        languageHeader: "Langue",
        authorHeader: "Auteur",
        emailLabel: "Mail",

        lang: {
            de: "Allemand",
            es: "Espagnol",
            fr: "Français",
            it: "Italien",
            ja: "Japonais",
            ptBr: "Portugais (Brésilien)",
            vi: "Viêtnamien",
            zh: "Chinois Traditionnel"
        },

        art: {
            corpLogo: "Logo « Code29 »",
            appLogo: "Logo « Fluidité »"
        }
    },

    taskDialog : {
        okButtonLabel : "Valider"
    },

    footer : {
        contactUs : "Contactez-nous",
        copyrightNotice : "Copyright © {start}-{end} {company}. Tous droits réservés.",
        findUsOnFacebook : "Retrouvez-nous sur Facebook",
        followUsOnTwitter : "Suivez-nous sur Twitter",
        contactUsViaEmail : "Envoyez-nous un mail",
        linkFacebook: "https://www.facebook.com/FluiditeApp",
        linkTwitter: "https://twitter.com/FluiditeApp",
        linkEmailUser: "contact",
        linkEmailDomain: "fluidite.app"
    }
};

export { messages };
