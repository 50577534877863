















import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

import CrossIcon from "@/scalar/cross.svg";

@Component({
    components: { CrossIcon }
})
export default class Popin extends Vue {
    @Prop({ default: 1000 }) public zIndex: number;
    @Prop({ default: false }) public modal: boolean;

    private emitClose(): void {
        this.$emit("close");
    }

    private surroundClick(): void {
        if (this.modal) { return; }
        this.emitClose();
    }

    private mounted(): void {
        const surroundEl:HTMLDivElement = this.$el.querySelector(".popin-surround");
        if (surroundEl !== null) { surroundEl.style.zIndex = "" + this.zIndex; }
        const containerEl:HTMLDivElement = this.$el.querySelector(".popin-container");
        if (containerEl !== null) { containerEl.style.zIndex = "" + (this.zIndex + 1); }
    }
}
