






import { Component, Vue } from "vue-property-decorator";

import ProductIcon from "@/scalar/product-icon.svg";

interface ISpanAnimStyle {
    animationName: string;
    animationDuration: string;
    animationDelay: string;
    animationFillMode: string;
}

interface ISubTitleSpan {
    id: number;
    text: string;
    style: ISpanAnimStyle|null;
    delimiterStyle: ISpanAnimStyle|null;
}

@Component({
    components : { ProductIcon }
})
export default class Splash extends Vue {}
