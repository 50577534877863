import Vue, { VNode } from "vue";

import { routes } from "./router";

import { i18n, loadDefaultLanguageAsync } from "@/setup/i18n-setup";
import Site from "@/views/Site.vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

Vue.config.productionTip = false;
Vue.config.devtools = (process.env.NODE_ENV !== "production");

const langPromise = loadDefaultLanguageAsync();
langPromise.then(() => {
    const v = new Vue({
        i18n,
        render: (h):VNode => h(Site),
        router: new VueRouter({ mode:"history", routes })
    });

    v.$mount("#site-mount");
});


