














import { Component, Vue } from "vue-property-decorator";

import LanguageSelect from "@/components/LanguageSelect.vue";
import NavBar from "@/components/nav/NavBar.vue";
import SiteFooter from "@/components/SiteFooter.vue";

@Component({
    components: { LanguageSelect, NavBar, SiteFooter }
})
export default class Site extends Vue {}
