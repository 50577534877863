// i18n-setup.js
import Vue from "vue";
import VueI18n from "vue-i18n";
import axios from "axios";

const defaultLocale = /^fr\b/.test(window.navigator.language) ? "fr" :
    (/^zh\b/.test(window.navigator.language) ? "zh" : "en-gb");

Vue.use(VueI18n);

export const i18n = new VueI18n({
    locale: defaultLocale, // set locale
    fallbackLocale: defaultLocale,
    messages: {}
});

const loadedLanguages:string[] = []; // our default language that is preloaded

function setI18nLanguage(lang: string): string {
    i18n.locale = lang;
    axios.defaults.headers.common["Accept-Language"] = lang;
    document.querySelector("html").setAttribute("lang", lang);
    return lang;
}

export function loadLanguageAsync(lang: string): Promise<string> {
    // If the same language
    if (i18n.locale === lang && loadedLanguages.includes(lang)) {
        return Promise.resolve(setI18nLanguage(lang));
    }

    // If the language hasn't been loaded yet
    return import(/* webpackChunkName: "lang-[request]" */ `@/lang/${lang}.ts`).then(
        module => {
            i18n.setLocaleMessage(lang, module.messages);
            loadedLanguages.push(lang);
            return setI18nLanguage(lang);
        }
    );
}

export function loadDefaultLanguageAsync(): Promise<string> {
    return loadLanguageAsync(defaultLocale);
}
